const host = process.env.REACT_APP_API_URL;

export async function getDates(destination_id) {
  const response = await fetch(`${host}/events/${destination_id}`);
  return await response.json();
}

export async function getEvent(event_id, date_id) {
  const response = await fetch(`${host}/date/${event_id}/${date_id}`);
  return await response.json();
}
