import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Destination from "./pages/Destination";
import Calendar from "./pages/Calendar";
import Event from "./pages/Event";
import Dates from "./pages/Dates";
import DatesBoxes from "./pages/DatesBoxes";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:destination_id" element={<Destination />} />
        <Route path="/:destination_id/calendar" element={<Calendar />} />
        <Route path="/:destination_id/dates" element={<Dates />} />
        <Route path="/:destination_id/upcoming" element={<DatesBoxes />} />
        <Route path="/event/:event_id/:date_id" element={<Event />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
