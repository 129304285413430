import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDates } from "../data/events";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Calendar = () => {
  const { destination_id } = useParams();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    getDates(destination_id).then((data) => {
      // Flatten the data and map to the format expected by FullCalendar
      const formattedDates = data.flat().map((event) => ({
        title: event.name,
        start: event.start_date,
        end: event.end_date,
        description: event.description,
        url: "/event/" + event.event_id + "/" + event.date_id,
      }));
      setDates(formattedDates);
    });
  }, [destination_id]);

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={dates}
      />
    </div>
  );
};

export default Calendar;
