import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent } from "../data/events";
import moment from "moment";
import "./Event.css"; // Importing the CSS file for styling

function Event() {
  const { event_id, date_id } = useParams();
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    // Fetch event data
    getEvent(event_id, date_id).then((data) => {
      setEventData(data);
    });
  }, [event_id, date_id]);

  if (!eventData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="event-container">
      <h1 className="event-title">{eventData.name}</h1>
      <div className="event-image-container">
        <img className="event-main-image" src={eventData.image} alt="Event" />
      </div>
      <div className="event-details">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
            gap: 20,
          }}
          onClick={() => {}}
        >
          <div
            style={{
              padding: 10,
              backgroundColor: "#f5f5f5",
              borderRadius: 10,
              width: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "#888",
              }}
            >
              Start
            </div>
            <div
              style={{
                fontSize: 20,
                color: "#333",
              }}
            >
              {moment(eventData.start_date).format("MMMM")}
            </div>
            <div
              style={{
                fontSize: 30,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              {moment(eventData.start_date).format("Do")}
            </div>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "#888",
              }}
            >
              {moment(eventData.start_date).format("h:mm A")}
            </div>
          </div>
          <div
            style={{
              padding: 10,
              backgroundColor: "#f5f5f5",
              borderRadius: 10,
              width: 200,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "#888",
              }}
            >
              End
            </div>
            <div
              style={{
                fontSize: 20,
                color: "#333",
              }}
            >
              {moment(eventData.end_date).format("MMMM")}
            </div>
            <div
              style={{
                fontSize: 30,
                fontWeight: "bold",
                color: "#333",
              }}
            >
              {moment(eventData.end_date).format("Do")}
            </div>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                color: "#888",
              }}
            >
              {moment(eventData.end_date).format("h:mm A")}
            </div>
          </div>
        </div>
        <p>{eventData.description}</p>
        <p>
          <strong>Location:</strong> {eventData.location.address}
        </p>
        <p>
          <strong>Phone:</strong> {eventData.phone}
        </p>
        <p>
          <strong>Website:</strong>{" "}
          <a href={eventData.website} target="_blank" rel="noopener noreferrer">
            {eventData.website}
          </a>
        </p>
      </div>
      <div className="event-media">
        <h2>Photos</h2>
        <div className="event-photos">
          {eventData.photos.map((photo, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `url(${photo.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: 200,
                width: 200,
                borderRadius: 12,
                margin: 5,
                cursor: "pointer",
                boxShadow: "0px 0px 10px 0px #ccc",
              }}
              onClick={() => {
                window.open(photo.url, "_blank");
              }}
            />
          ))}
        </div>
        {eventData.video && eventData.video.url && (
          <>
            <h2>Video</h2>
            <video
              controls
              src={eventData.video.url}
              className="event-video"
            ></video>{" "}
          </>
        )}
        {eventData.audio && eventData.audio.url && (
          <>
            <h2>Audio</h2>
            <audio
              controls
              src={eventData.audio.url}
              className="event-audio"
            ></audio>
          </>
        )}
      </div>
      <div
        style={{
          position: "fixed",
          top: 10,
          left: 10,
        }}
      >
        <div
          style={{
            height: 30,
            backgroundColor: "white",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 10px 0px #ccc",
            padding: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            window.history.back();
          }}
        >
          📅 BACK
        </div>
      </div>
    </div>
  );
}

export default Event;
