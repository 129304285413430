import React, { useEffect, useState } from "react";
import { getDestinations } from "../data/destinations";

const Home = () => {
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    getDestinations().then((data) => {
      setDestinations(data);
    });
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          marginBottom: 20,
        }}
      >
        Destinations
      </div>
      <div
        style={{
          fontSize: 16,
          marginBottom: 20,
        }}
      >
        Select your destination
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        {destinations.map((destination) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location.href = `/${destination.id}`;
            }}
          >
            <div
              style={{
                padding: 10,
                border: "1px solid #ccc",
                margin: 10,
                borderRadius: 15,
                width: 200,
                height: 200,
                backgroundImage: `url(${destination.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                boxShadow: "0px 0px 10px 0px #ccc",
              }}
              key={destination.id}
            ></div>
            <h3>{destination.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
