import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDestination } from "../data/destinations";

function Destination() {
  const { destination_id } = useParams();
  const [destination, setDestination] = useState(null);

  useEffect(() => {
    getDestination(destination_id).then((data) => {
      setDestination(data);
    });
  }, [destination_id]);

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          marginBottom: 20,
        }}
      >
        {destination?.name}
      </div>

      {/* Add your page content here */}
      <div
        style={{
          fontSize: 26,
          marginBottom: 20,
          fontWeight: "bold",
          borderBottom: "1px solid #ccc",
          paddingBottom: 10,
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = `/${destination_id}/calendar`;
        }}
      >
        📆 Calendar
      </div>
      <div
        style={{
          fontSize: 26,
          marginBottom: 20,
          fontWeight: "bold",
          borderBottom: "1px solid #ccc",
          paddingBottom: 10,
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = `/${destination_id}/dates`;
        }}
      >
        📄 Upcoming List
      </div>
      <div
        style={{
          fontSize: 26,
          marginBottom: 20,
          fontWeight: "bold",
          borderBottom: "1px solid #ccc",
          paddingBottom: 10,
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = `/${destination_id}/upcoming`;
        }}
      >
        ⬜️ Upcoming
      </div>
    </div>
  );
}

export default Destination;
