import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDates } from "../data/events";
import moment from "moment";
import "./DatesBoxes.css"; // Importing the CSS file for styling

const DatesBoxes = () => {
  const { destination_id } = useParams();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    getDates(destination_id).then((data) => {
      setDates(data.flat()); // Flatten the nested arrays
    });
  }, [destination_id]);

  const renderDateItem = (date) => {
    const startDate = moment(date.start_date);
    const endDate = moment(date.end_date);
    const dateNumber = startDate.format("Do");

    return (
      <div
        key={date.date_id}
        className="box-date-box"
        onClick={() => {
          window.location.href = `/event/${date.event_id}/${date.date_id}`;
        }}
      >
        <div
          className="box-date-image-box"
          style={{ backgroundImage: `url(${date.image})` }}
        >
          <span className="box-date-number">{dateNumber}</span>
        </div>
        <div className="box-date-details">
          <h3>{date.name}</h3>
          <p>
            {startDate.format("MMMM Do, YYYY")} from{" "}
            {startDate.format("h:mm A")} to {endDate.format("h:mm A")}
            {startDate.isSame(endDate, "day") ? (
              ""
            ) : (
              <> on {endDate.format("MMMM Do, YYYY")}</>
            )}
          </p>
          <p className="box-date-description">{date.description}</p>
        </div>
      </div>
    );
  };

  const groupDatesByMonth = (dates) => {
    return dates.reduce((acc, date) => {
      const month = moment(date.start_date).format("MMMM YYYY");
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(date);
      return acc;
    }, {});
  };

  const groupedDates = groupDatesByMonth(dates);

  return (
    <div className="box-dates-container">
      {Object.keys(groupedDates).map((month) => (
        <div key={month}>
          <div className="box-month-header">{month}</div>
          <div className="box-dates-row">
            {groupedDates[month].map(renderDateItem)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DatesBoxes;
