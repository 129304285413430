const host = process.env.REACT_APP_API_URL;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export async function getDestinations() {
  const response = await fetch(`${host}/destinations`, {
    headers: headers,
  });
  return await response.json();
}

export async function getDestination(destination_id) {
  const response = await fetch(`${host}/destination/${destination_id}`, {
    headers: headers,
  });
  return await response.json();
}
